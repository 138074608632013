import React from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';

import backgroundImg from 'assets/pngs/special_deal_background.jpg';
import specialDealImage from 'assets/pngs/mental_health_week.png';
import FullScreenWrapper from 'components/FullScreenWrapper';
import Header from 'components/Header';
import Button from 'components/Button';
import Text from 'components/Text';
import { setCookie, COOKIE_KEY } from 'utils/cookies';
import { ROUTES } from 'utils/constants';
import { usePricing } from 'state/PricingStateProvider';

const Container = styled.div({
  textAlign: 'center',
  maxWidth: 315,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const OrangeText = styled.span((props) => ({
  color: props.theme.colors.orange,
}));

const Title = styled(Text)({
  fontWeight: 700,
  textAlign: 'center',
  lineHeight: '36px',
  marginBottom: 6,
});

const Subtitle = styled(Text)({
  lineHeight: '22px',
  opacity: 0.9,
  marginBottom: 30,
});

const Image = styled.img({
  maxWidth: 180,
  width: '100%',
});

const OfferInfo = styled(Text)({
  fontWeight: 500,
  fontSize: 11,
  lineHeight: '16px',
  marginTop: 32,
  marginBottom: 16,
  opacity: 0.5,
});

const StyledButton = styled(Button)({
  maxWidth: 230,
});

const MentalHealthWeek: React.FC = () => {
  const mentalHealthWeekCoupon = 'MHW60';
  const { setAppliedCoupon } = usePricing();

  const onClick = () => {
    setCookie(COOKIE_KEY.COUPON, mentalHealthWeekCoupon);
    setAppliedCoupon(mentalHealthWeekCoupon);
    navigate(ROUTES.MAIN);
  };

  return (
    <FullScreenWrapper backgroundImage={backgroundImg}>
      <Header isVisible />
      <Container>
        <Title type="h1" align="center">
          MENTAL HEALTH WEEK SAVE <OrangeText>60%</OrangeText>
        </Title>
        <Subtitle align="center">
          Reduce stress and anxiety, fall asleep and find inner peace effortlessly
        </Subtitle>
        <Image src={specialDealImage} />
        <OfferInfo align="center">
          Your discount will be applied automatically at the checkout
        </OfferInfo>
        <StyledButton text="Claim Offer" onClick={onClick} />
      </Container>
    </FullScreenWrapper>
  );
};

export default MentalHealthWeek;
